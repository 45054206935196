import service from '@/utils/request'

export const login = (data) => {
  return service({
    url: '/user/login',
    method: 'post',
    data
  })
}

export const wechat = (params) => {
  return service({
    url: '/user/wechat',
    method: 'get',
    params
  })
}

export const projects = (params) => {
  return service({
    url: '/user/projects',
    method: 'get',
    params
  })
}

export const project = (params) => {
  return service({
    url: '/user/project',
    method: 'get',
    params
  })
}

export const save = (data) => {
  return service({
    url: '/user/save',
    method: 'post',
    data
  })
}

export const special = (data) => {
  return service({
    url: '/user/special',
    method: 'post',
    data
  })
}

export const progress = (params) => {
  return service({
    url: '/user/progress',
    method: 'get',
    params
  })
}


// export const loginout = (data) => {
//   return service({
//     url: '/user/logout',
//     method: 'post',
//     data
//   })
// }